<script setup lang="ts">
  import IconSettings from '@/assets/icons/Settings.svg'
  import { useCurrentUser } from '@/modules/base/composable/useCurrentUser'
  import { Route } from '@/modules/base/config/routesConfig'
  import MenuLink from './MenuLink.vue'

  const { isAdmin } = useCurrentUser()
</script>

<template>
  <MenuLink
    data-testid="the-menu-link-settings"
    match-exact
    :to="{ name: Route.SettingsDefault }"
  >
    <template #icon>
      <IconSettings />
    </template>
  </MenuLink>
  <MenuLink
    v-if="isAdmin"
    data-testid="the-menu-link-settings-users"
    submenu
    :to="{ name: Route.SettingsUsers }"
  />
  <MenuLink
    v-if="isAdmin"
    data-testid="the-menu-link-settings-locations"
    submenu
    :to="{ name: Route.SettingsLocations }"
  />
  <MenuLink
    v-if="isAdmin"
    data-testid="the-menu-link-settings-organization"
    submenu
    :to="{ name: Route.SettingsOrganization }"
  />
  <MenuLink
    data-testid="the-menu-link-settings-my-account"
    submenu
    :to="{ name: Route.SettingsMyAccount }"
  />
  <MenuLink
    v-if="isAdmin"
    data-testid="the-menu-link-audit"
    submenu
    :to="{ name: Route.Audit }"
  />
</template>
