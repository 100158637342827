<script setup lang="ts">
  import IconAdd from '@/assets/icons/Add.svg'
  import IconDoubleArrow from '@/assets/icons/DoubleArrow.svg'
  import { useAccess } from '@/modules/base/composable/useAccess'
  import {
    useModalView,
    ModalQuery,
  } from '@/modules/base/composable/useModalView'
  import { useLayoutStore } from '@/modules/base/stores/useLayoutStore'
  import AppLogo from './AppLogo.vue'
  import TheCompanySwitcher from './TheCompanySwitcher.vue'
  import TheHeaderUser from './TheHeaderUser.vue'

  const layout = useLayoutStore()
  const { open: openActionCreateModal } = useModalView(ModalQuery.ActionCreate)
  const { hasWriteAccess } = useAccess()
</script>

<template>
  <header
    class="light:shadow-lg bg-gray-var-900 grid w-full grid-cols-[auto,1fr] items-center px-6 py-2 shadow-2xl"
    :class="{ 'supports-subgrid:grid-cols-subgrid': layout.isMenuOpen }"
    data-testid="the-heading"
  >
    <BaseButton
      aria-controls="the-menu"
      :aria-expanded="layout.isMenuOpen"
      class="-mx-3 flex shrink-0 gap-5 px-3"
      data-testid="the-header-open-menu"
      @click="layout.setIsMenuOpen(!layout.isMenuOpen)"
    >
      <AppLogo :square="!layout.isMenuOpen" />
      <span class="sr-only">
        {{
          layout.isMenuOpen ? $t('global.menu.close') : $t('global.menu.open')
        }}
      </span>
      <IconDoubleArrow
        :class="{
          '-scale-x-100': !layout.isMenuOpen,
        }"
      />
    </BaseButton>

    <div class="flex items-center">
      <div class="mr-auto" data-testid="company-switcher">
        <TheCompanySwitcher />
      </div>
      <BaseButton
        v-if="hasWriteAccess"
        class="flex items-center gap-2 text-xl"
        data-testid="action-add"
        @click="openActionCreateModal"
      >
        <IconAdd />
        <span>{{ $t('global.header.addAction') }}</span>
      </BaseButton>
      <TheHeaderUser class="-mr-1.5" />
    </div>
  </header>
</template>
