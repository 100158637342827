<script setup lang="ts">
  import IconReduction from '@/assets/icons/Reduction.svg'
  import { useCurrentUser } from '@/modules/base/composable/useCurrentUser'
  import { Route } from '@/modules/base/config/routesConfig'
  import MenuLink from './MenuLink.vue'

  const { isAdmin } = useCurrentUser()
</script>

<template>
  <MenuLink
    data-testid="the-menu-link-reduction"
    match-exact
    :to="{ name: Route.Actions }"
  >
    <template #icon>
      <IconReduction />
    </template>
  </MenuLink>
  <MenuLink
    data-testid="the-menu-link-reduction-roadmap"
    submenu
    :to="{ name: Route.ActionsRoadmap }"
  />
  <MenuLink
    data-testid="the-menu-link-reduction-insights"
    submenu
    :to="{ name: Route.Insights }"
  />
  <MenuLink
    v-if="isAdmin"
    data-testid="the-menu-link-reduction-actions"
    submenu
    :to="{ name: Route.AssessmentActions }"
  />
  <MenuLink
    v-if="isAdmin"
    data-testid="the-menu-link-reduction-calculations"
    submenu
    :to="{ name: Route.AssessmentCalculations }"
  />
  <MenuLink
    v-if="isAdmin"
    data-testid="the-menu-link-reduction-net-zero"
    submenu
    :to="{ name: Route.AssessmentReductionPath }"
  />
  <MenuLink
    v-if="isAdmin"
    data-testid="the-menu-link-reduction-emissions"
    submenu
    :to="{ name: Route.AssessmentEmissions }"
  />
</template>
