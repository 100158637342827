<script setup lang="ts">
  import { computed, ref } from 'vue'
  import { useRouter } from 'vue-router'
  import {
    useQueryBannersCurrent,
    useMutationBannerInteract,
  } from '@/api/useBanners'
  import { BannerInteractionKind } from '@/api/useBanners.types'
  import CloseIcon from '@/assets/icons/Close.svg'
  import { useBannerSharedStore } from '@/modules/app/stores/useBannerSharedStore'

  const router = useRouter()

  const { data: banner } = useQueryBannersCurrent()
  const { mutateAsync: mutateInteract } = useMutationBannerInteract(
    computed(() => banner.value?.current?.id ?? null)
  )

  const bannerStore = useBannerSharedStore()
  const interact = async (interactionKind: BannerInteractionKind) => {
    bannerStore.show = false
    await mutateInteract({
      interaction: {
        kind: interactionKind,
      },
    })
  }
  const openLink = async () => {
    await interact(BannerInteractionKind.LinkClicked)
    const href = banner.value?.current?.link?.href
    if (href) {
      if (href.startsWith('/')) {
        router.push(href)
      } else {
        window.open(href)
      }
    }
  }

  const isLoadingAssetsFinished = ref(false)
</script>

<template>
  <template v-if="banner?.current && bannerStore.show">
    <section
      class="relative flex items-center gap-8 py-4 pl-16 pr-8"
      :class="{
        invisible: !isLoadingAssetsFinished,
      }"
      data-testid="app-banner"
    >
      <div class="mr-auto">
        <h2 class="text-2xl font-extrabold">
          {{ banner.current.title }}
        </h2>
        <p>{{ banner.current.message }}</p>
      </div>
      <BaseButton
        v-if="banner.current.link"
        class="shrink-0"
        color="text-white-var"
        data-testid="app-banner-link"
        primary
        @click="openLink"
      >
        {{ banner.current.link.text }}
      </BaseButton>
      <BaseButton
        class="shrink-0"
        data-testid="app-banner-dismiss"
        @click="interact(BannerInteractionKind.Dismissed)"
      >
        <CloseIcon class="h-6 w-6" />
        <span class="sr-only">{{ $t('global.menu.close') }}</span>
      </BaseButton>
      <div class="absolute inset-0 -z-10">
        <img
          aria-hidden
          class="h-full w-full object-cover"
          loading="eager"
          src="@/assets/images/WaveBackground.svg?noTransform"
          @load="isLoadingAssetsFinished = true"
        />
      </div>
    </section>
  </template>
</template>
